.places-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.place-card {
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
  text-align: center;
}

.place-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.place-card h2 {
  margin: 15px 0 10px;
  font-size: 1.5rem;
}

.place-card p {
  margin: 5px 0;
  font-size: 1rem;
  color: #666;
}
