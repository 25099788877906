/* src/components/Footer.css */
.footer {
  background-color: #333;
  color: #fff;
  padding: 40px 20px;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
}

.footer-section h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #f1c40f; /* Golden color */
}

.footer-section p,
.footer-section a {
  font-size: 1rem;
  color: #ccc;
  text-decoration: none;
}

.footer-section a:hover {
  color: #f1c40f;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  font-size: 1.5rem;
  color: #ccc;
}

.social-icons a:hover {
  color: #f1c40f;
}

.footer-bottom {
  text-align: center;
  margin-top: 40px;
  border-top: 1px solid #444;
  padding-top: 20px;
}

.footer-bottom p {
  margin: 0;
  font-size: 1rem;
  color: #ccc;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    text-align: center;
    margin-bottom: 20px;
  }
}
