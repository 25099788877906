@media (max-width: 768px) {
  .room-details-container {
    flex-direction: column;
    align-items: center;
  }

  .room-details-info {
    text-align: center;
    padding: 10px;
  }

  .room-footer {
    flex-direction: column;
    gap: 10px;
  }
}

.offer-details{
  font-weight: 500;
  font-size: 17px;
  color: #000000bd;
}
.terms-conditions{
  font-weight: 500;
  font-size: 17px;
  color: #000000bd;
}

li {
  margin-bottom: 10px;
}
.book-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 20px;
  width: 80%;
  max-width: 600px;
}

.book-card {
  width: 100%;
  padding: 20px;
  background: #fff;
  border: 1px solid #0000003b;
}

.book-card-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-field {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #007bff;
}

.input-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 12px;
}

.control-btn {
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
}

.book-now-btn-container {
  text-align: center;
  margin-top: 20px;
}

.book-now-btn {
  padding: 18px 145px;
  background-color: darkgoldenrod;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.detailes-page-footer {
  display: flex;
  flex-wrap: wrap;
  background-color: #404040;
  padding: 40px;
  justify-content: space-between;
  height: 210px;
}

.detailes-page-footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.detailes-page-footer-section h4 {
  font-size: 18px;
  margin-bottom: 15px;
  color: darkgoldenrod;
}

.detailes-page-footer-section ul {
  list-style: none;
  padding: 0;
}

.detailes-page-footer-section ul li {
  margin-bottom: 10px;
}

.detailes-page-footer-section ul li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
}

.detailes-page-footer-section ul li a:hover {
  color: darkgoldenrod;
}

.detailes-page-footer-bottom{
  display: flex;
  align-items: end;
  gap: 10px;
}

.detailes-page-footer-bottom img.company-logo {
  max-width: 15px;
}

.detailes-page-footer-bottom a {
  color: #ffffff57;
    text-decoration: none;
    font-size: 10px;
    font-weight: bold;
}

.detailes-page-footer-bottom p {
  margin: 5px 0 0;
  font-size: 12px;
  color: #777;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .detailes-page-footer-section {
    width: 100%;
    text-align: center;
  }

  .detailes-page-footer-bottom{
    text-align: center;
  }
}

@media (max-width: 480px) {
  .detailes-page-footer-section h4 {
    font-size: 14px;
  }

  .detailes-page-footer-section ul li a {
    font-size: 13px;
  }


  .detailes-page-footer-bottom a {
    font-size: 13px;
  }

  .detailes-page-footer-bottom p {
    font-size: 11px;
  }
}

/* Main container styling */
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Style for the left and right sections */
.left-section, .right-section {
  flex: 1;
  min-width: 300px;
  /* padding: 20px; */
}

/* Responsive styling */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    margin-left: -48px;
  }

  .left-section, .right-section {
    width: 100%;
    margin-left: -30px;
  }

  .book-card {
    margin-top: 20px;
  }

  .book-now-btn {
    width: 100%;
  }
}

/* Offer Details Styling */
.offer-details-container {
  margin-top: 20px;
}

.offer-details-container div {
  margin-bottom: 15px;
}

/* Book Card Styling */
.book-card-container {
  padding: 20px;
  border-radius: 10px;
}

.book-card-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

.input-control {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-control button {
  padding: 5px 10px;
}

.book-now-btn {
  background-color: darkgoldenrod;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.book-now-btn:hover {
  background-color: #c49b13;
}
