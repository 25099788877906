/* src/components/StickyHeader.css */
.header {
  background-color: #f8f9fa;
  padding: 20px;
  width: 100%;
  position: relative;
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  box-shadow: none;
}

.header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
}

.nav ul li {
  margin: 0 15px;
}

.nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
}

.nav ul li a:hover {
  color: #007bff;
}
