/* src/components/RoomList.css */
.room-list {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  /* border: 1px solid red; */
  justify-content: center;
  /* background: #e7e7e7d6; */
  background: #000;
}

.room-card {
  width: 200px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
  text-align: center;
}

.room-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.room-card h2 {
  margin: 15px 0 10px;
  font-size: 1.25rem;
}

.room-card p {
  margin: 5px 0;
  font-size: 1rem;
  color: #666;
}
