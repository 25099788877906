/* src/components/JourneyTimeline.css */
.journey-timeline {
  padding: 10px 20px;
  background-color: #000;
  text-align: center;
}

.journey-timeline h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}

.timeline-item {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.timeline-item:hover {
  transform: scale(1.05);
}

.timeline-icon {
  font-size: 2.5rem;
  margin-right: 20px;
  color: #007bff;
}

.timeline-content h3 {
  margin: 0;
  font-size: 1.75rem;
  color: #333;
}

.timeline-content p {
  margin: 5px 0 0;
  font-size: 1.1rem;
  color: #666;
}

@media (max-width: 768px) {
  .timeline-item {
    flex-direction: column;
    text-align: center;
  }

  .timeline-icon {
    margin-bottom: 15px;
  }
}
