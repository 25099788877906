/* src/components/ThingsToDo.css */
.things-to-do {
  padding: 0px 20px;
  background-color: #000;
  text-align: center;
}

.things-to-do h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.activity-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.activity-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.activity-card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.activity-image {
  height: 200px;
  overflow: hidden;
}

.activity-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.activity-card:hover .activity-image img {
  transform: scale(1.1);
}

.activity-content {
  padding: 20px;
}

.activity-content h3 {
  margin: 0;
  font-size: 1.75rem;
  color: #333;
}

.activity-content p {
  margin: 10px 0 0;
  font-size: 1rem;
  color: #666;
}
